import { Link } from '@/routes-utils/navigation.tsx';
import { mentionNode } from './plugin.css';

export const MentionNodeComponent = ({ username }: { username: string }) => {
  return (
    // TODO: use userid as its more stable
    <Link className={mentionNode} to={`/${username}`}>
      @{username}
    </Link>
  );
};

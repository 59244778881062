import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $getNodeByKey, CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect, useRef } from 'react';
import { mentionNode } from './plugin.css';

export const EditableMentionNodeComponent = ({
  nodeKey,
  text,
}: {
  nodeKey: string;
  text: string;
}) => {
  const [editor] = useLexicalComposerContext();
  const ref = useRef(null);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        (event: MouseEvent) => {
          if (event.target === ref.current) {
            $getNodeByKey(nodeKey)?.selectEnd();
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, nodeKey]);

  return (
    <span ref={ref} className={mentionNode}>
      {text}
    </span>
  );
};

import { LinkNode, AutoLinkNode } from './link';
import { MentionNode } from './mention';
import { OverflowNode } from './overflow';
import { HeadingNode, QuoteNode } from './rich-text';
import { CodeNode, CodeHighlightNode } from './code';
import { ListItemNode, ListNode } from './list';

export const makePostLexicalNodes = () => {
  return [
    LinkNode,
    AutoLinkNode,
    MentionNode,
    OverflowNode,
    HeadingNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    ListNode,
    ListItemNode,
  ];
};

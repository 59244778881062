/**
 * hard-forked from https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/FloatingTextFormatToolbarPlugin/index.tsx
 *
 */

import './plugin.css.ts';

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { $isCodeHighlightNode } from '@synoptic/lexical-nodes/code.js';
import {
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@synoptic/lexical-nodes/link.ts';
import { $isListNode } from '@synoptic/lexical-nodes/list.js';
import { $isHeadingNode } from '@synoptic/lexical-nodes/rich-text.js';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { IconButton } from '@synoptic/ui-kit/button/icon-button.js';
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from '@synoptic/ui-kit/dropdown-menu/dropdown-menu.js';
import { BoldIcon } from '@synoptic/ui-kit/icons/react/bold.js';
import { CaretArrowDownIcon } from '@synoptic/ui-kit/icons/react/caret-arrow-down.js';
import { CodeIcon } from '@synoptic/ui-kit/icons/react/code.js';
import { ItalicIcon } from '@synoptic/ui-kit/icons/react/italic.js';
import { LinkIcon } from '@synoptic/ui-kit/icons/react/link.js';
import { StrikethroughIcon } from '@synoptic/ui-kit/icons/react/strikethrough.js';
import { SubscriptIcon } from '@synoptic/ui-kit/icons/react/subscript.js';
import { SuperscriptIcon } from '@synoptic/ui-kit/icons/react/superscript.js';
import { UnderlineIcon } from '@synoptic/ui-kit/icons/react/underline.js';
import { Portal } from '@synoptic/ui-kit/portal.js';
import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { getDOMRangeRect } from '../../utils/get-dom-range-rect';
import { getDOMSelection } from '../../utils/get-dom-selection.ts';
import { getSelectedNode } from '../../utils/get-selected-node';
import { setFloatingElemPosition } from '../../utils/set-floating-elem-position';
import {
  formatBulletList,
  formatHeading,
  formatNumberedList,
  formatParagraph,
} from './commands.ts';
import { floatingMenu } from './plugin.css.ts';

const blockTypes = {
  paragraph: 'Normal',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  bullet: 'Bullet List',
  number: 'Numbered List',
};

function TextFormatFloatingToolbar({
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
  isSubscript,
  isSuperscript,
  setIsLinkEditMode,
  blockType,
}: {
  editor: LexicalEditor;
  anchorElem: HTMLElement;
  isBold: boolean;
  isCode: boolean;
  isItalic: boolean;
  isLink: boolean;
  isStrikethrough: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
  isUnderline: boolean;
  setIsLinkEditMode: Dispatch<boolean>;
  blockType: string;
}) {
  const popupCharStylesEditorRef = useRef<HTMLDivElement | null>(null);

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true);
    } else {
      setIsLinkEditMode(false);
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink, setIsLinkEditMode]);

  function mouseMoveListener(e: MouseEvent) {
    if (
      popupCharStylesEditorRef?.current &&
      (e.buttons === 1 || e.buttons === 3)
    ) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'none') {
        const x = e.clientX;
        const y = e.clientY;
        const elementUnderMouse = document.elementFromPoint(x, y);

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = 'none';
        }
      }
    }
  }
  function mouseUpListener() {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== 'auto') {
        popupCharStylesEditorRef.current.style.pointerEvents = 'auto';
      }
    }
  }

  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener('mousemove', mouseMoveListener);
      document.addEventListener('mouseup', mouseUpListener);

      return () => {
        document.removeEventListener('mousemove', mouseMoveListener);
        document.removeEventListener('mouseup', mouseUpListener);
      };
    }
  }, [popupCharStylesEditorRef]);

  const $updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current;
    const nativeSelection = getDOMSelection(editor._window);

    if (popupCharStylesEditorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement);

      setFloatingElemPosition(
        rangeRect,
        popupCharStylesEditorElem,
        anchorElem,
        isLink,
      );
    }
  }, [editor, anchorElem, isLink]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        $updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener('resize', update);
    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);
      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [editor, $updateTextFormatFloatingToolbar, anchorElem]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      $updateTextFormatFloatingToolbar();
    });
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateTextFormatFloatingToolbar();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          $updateTextFormatFloatingToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor, $updateTextFormatFloatingToolbar]);

  return (
    <Portal
      container={anchorElem}
      ref={popupCharStylesEditorRef}
      className={floatingMenu}
    >
      {editor.isEditable() && (
        <>
          <DropdownMenuRoot>
            <DropdownMenuTrigger>
              <Button
                variant="ghost"
                size="small"
                endIcon={<CaretArrowDownIcon />}
              >
                {blockType in blockTypes
                  ? blockTypes[blockType as keyof typeof blockTypes]
                  : blockType}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem onSelect={() => formatParagraph(editor)}>
                Normal
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => formatHeading(editor, blockType, 'h1')}
              >
                Heading 1
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => formatHeading(editor, blockType, 'h2')}
              >
                Heading 2
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => formatHeading(editor, blockType, 'h3')}
              >
                Heading 3
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => formatBulletList(editor, blockType)}
              >
                Bullet List
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => formatNumberedList(editor, blockType)}
              >
                Numbered List
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenuRoot>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
            }}
            title="Bold"
            aria-label="Format text as bold"
            pressed={isBold}
          >
            <BoldIcon />
          </IconButton>

          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
            }}
            title="Italic"
            aria-label="Format text as italics"
            pressed={isItalic}
          >
            <ItalicIcon />
          </IconButton>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
            title="Underline"
            aria-label="Format text to underlined"
            pressed={isUnderline}
          >
            <UnderlineIcon />
          </IconButton>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
            }}
            title="Strikethrough"
            aria-label="Format text with a strikethrough"
            pressed={isStrikethrough}
          >
            <StrikethroughIcon />
          </IconButton>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'subscript');
            }}
            title="Subscript"
            aria-label="Format Subscript"
            pressed={isSubscript}
          >
            <SubscriptIcon />
          </IconButton>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'superscript');
            }}
            title="Superscript"
            aria-label="Format Superscript"
            pressed={isSuperscript}
          >
            <SuperscriptIcon />
          </IconButton>

          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'code');
            }}
            title="Format as code"
            aria-label="Format as code"
            pressed={isCode}
          >
            <CodeIcon />
          </IconButton>
          <IconButton
            variant="ghost"
            size="small"
            rounded={false}
            onClick={insertLink}
            title="Insert link"
            aria-label="Insert link"
            pressed={isLink}
          >
            <LinkIcon />
          </IconButton>
        </>
      )}
    </Portal>
  );
}

function useFloatingTextFormatToolbar(
  editor: LexicalEditor,
  anchorElem: HTMLElement,
  setIsLinkEditMode: Dispatch<boolean>,
) {
  const [isText, setIsText] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isSubscript, setIsSubscript] = useState(false);
  const [isSuperscript, setIsSuperscript] = useState(false);
  const [isCode, setIsCode] = useState(false);

  const [blockType, setBlockType] = useState<string>('paragraph');

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return;
      }
      const selection = $getSelection();
      const nativeSelection = getDOMSelection(editor._window);
      const rootElement = editor.getRootElement();

      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false);
        return;
      }

      if (!$isRangeSelection(selection)) {
        return;
      }

      const node = getSelectedNode(selection);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsSubscript(selection.hasFormat('subscript'));
      setIsSuperscript(selection.hasFormat('superscript'));
      setIsCode(selection.hasFormat('code'));

      // Update links
      const parent = node.getParent();
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }

      if (
        !$isCodeHighlightNode(selection.anchor.getNode()) &&
        selection.getTextContent() !== ''
      ) {
        setIsText($isTextNode(node) || $isParagraphNode(node));
      } else {
        setIsText(false);
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, '');
      if (!selection.isCollapsed() && rawTextContent === '') {
        setIsText(false);
        return;
      }

      const blockNode = $isRootOrShadowRoot(node)
        ? node
        : node.getTopLevelElementOrThrow();

      if ($isHeadingNode(blockNode)) {
        setBlockType(blockNode.getTag());
      } else if ($isListNode(blockNode)) {
        setBlockType(blockNode.getListType());
      } else {
        setBlockType(blockNode.getType());
      }
    });
  }, [editor]);

  useEffect(() => {
    document.addEventListener('selectionchange', updatePopup);
    return () => {
      document.removeEventListener('selectionchange', updatePopup);
    };
  }, [updatePopup]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup();
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false);
        }
      }),
    );
  }, [editor, updatePopup]);

  if (!isText) {
    return null;
  }

  return (
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isLink={isLink}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isSubscript={isSubscript}
      isSuperscript={isSuperscript}
      isUnderline={isUnderline}
      isCode={isCode}
      blockType={blockType}
      setIsLinkEditMode={setIsLinkEditMode}
    />
  );
}

export function FloatingTextFormatToolbarPlugin({
  anchorElem = document.body,
  setIsLinkEditMode,
}: {
  anchorElem?: HTMLElement;
  setIsLinkEditMode: Dispatch<boolean>;
}) {
  const [editor] = useLexicalComposerContext();
  return useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode);
}

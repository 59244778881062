/**
 * based on https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/MentionsPlugin/index.tsx
 */

import {
  TypeaheadQueryQuery,
  TypeaheadQueryQueryVariables,
  UserBadgeFragmentFragment,
} from '@/__generated__/graphql';
import { QueryRef, useReadQuery } from '@apollo/client';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { $createMentionNode } from '@synoptic/lexical-nodes/mention.js';
import { Portal } from '@synoptic/ui-kit/portal.js';
import { TextNode } from 'lexical';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useTypeahead } from '../../../search-typeahed/use-typeahead';
import { UserBadge } from '../../../user-badge/user-badge';
import { useIsFocused } from '../../utils/use-is-focused';
import {
  typeaheadEmpty,
  typeaheadPopover,
  typeaheadPopoverContainer,
  typeaheadPopoverItem,
} from './plugin.css';

const TRIGGERS = ['@'].join('');

const VALID_CHARS = '\\w';

const LENGTH_LIMIT = 25;

const AtSignMentionsRegex = new RegExp(
  '(^|\\s|\\()(' +
    '[' +
    TRIGGERS +
    ']' +
    '((?:' +
    VALID_CHARS +
    '){0,' +
    LENGTH_LIMIT +
    '})' +
    ')$',
);

function checkForAtSignMentions(
  text: string,
  minMatchLength: number,
): MenuTextMatch | null {
  const match = AtSignMentionsRegex.exec(text);

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignMentions(text, 1);
}

class MentionTypeaheadOption extends MenuOption {
  user: UserBadgeFragmentFragment;

  constructor(key: string, user: UserBadgeFragmentFragment) {
    super(key);
    this.user = user;
  }
}

function MentionsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={typeaheadPopoverItem}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={'typeahead-item-' + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
    >
      <UserBadge id={option.key} size="small" underlineTitleOnHover={false} />
    </li>
  );
}

const MentionsMenu = ({
  options,
  setOptions,
  selectedIndex,
  setHighlightedIndex,
  selectOptionAndCleanUp,
  queryRef,
}: {
  options: MentionTypeaheadOption[];
  setOptions: (v: MentionTypeaheadOption[]) => void;
  selectedIndex: number | null;
  setHighlightedIndex: (index: number) => void;
  selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void;
  queryRef: QueryRef<TypeaheadQueryQuery, TypeaheadQueryQueryVariables>;
}) => {
  const {
    data: {
      typeahead: { users },
    },
  } = useReadQuery(queryRef);

  useEffect(() => {
    setOptions(users.map((user) => new MentionTypeaheadOption(user.id, user)));

    return () => {
      setOptions([]);
    };
  }, [setOptions, users]);

  return options.length > 0 ? (
    <ul className={typeaheadPopover}>
      {options.map((option, i: number) => (
        <MentionsTypeaheadMenuItem
          index={i}
          isSelected={selectedIndex === i}
          onClick={() => {
            setHighlightedIndex(i);
            selectOptionAndCleanUp(option);
          }}
          onMouseEnter={() => {
            setHighlightedIndex(i);
          }}
          key={option.key}
          option={option}
        />
      ))}
    </ul>
  ) : (
    <div className={typeaheadPopover}>
      <div className={typeaheadEmpty}>No users found</div>
    </div>
  );
};

export const MentionsPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const isEditorFocused = useIsFocused();

  const { onValueChange, queryRef } = useTypeahead({ limit: 5 });

  const [options, setOptions] = useState<MentionTypeaheadOption[]>([]);

  const onSelectOption = useCallback(
    (
      selectedOption: MentionTypeaheadOption,
      nodeToReplace: TextNode | null,
      closeMenu: () => void,
    ) => {
      editor.update(() => {
        const mentionNode = $createMentionNode(
          selectedOption.user.username,
          selectedOption.key,
        );
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode);
        }
        closeMenu();
      });
    },
    [editor],
  );

  return (
    <LexicalTypeaheadMenuPlugin<MentionTypeaheadOption>
      onQueryChange={onValueChange}
      onSelectOption={onSelectOption}
      triggerFn={getPossibleQueryMatch}
      options={options}
      anchorClassName={typeaheadPopoverContainer}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) =>
        anchorElementRef.current && queryRef && isEditorFocused ? (
          <Portal container={anchorElementRef.current} asChild>
            <Suspense
              fallback={
                <div className={typeaheadPopover}>
                  <div className={typeaheadEmpty}>Loading...</div>
                </div>
              }
            >
              <MentionsMenu
                options={options}
                setOptions={setOptions}
                queryRef={queryRef}
                selectOptionAndCleanUp={selectOptionAndCleanUp}
                selectedIndex={selectedIndex}
                setHighlightedIndex={setHighlightedIndex}
              />
            </Suspense>
          </Portal>
        ) : null
      }
    />
  );
};

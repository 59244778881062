import { $createTextNode } from 'lexical';

import { $createParagraphNode, $getRoot } from 'lexical';

import { LexicalEditor } from 'lexical';

export const $prepopulateJson = (editor: LexicalEditor, json: string) => {
  try {
    if (json.startsWith('{')) {
      const parsed = editor.parseEditorState(json);
      editor.setEditorState(parsed);
      return;
    } else {
      console.log(
        '[text-editor]: initial state was passed as regular text instead of stringified Lexical state',
      );
    }
  } catch (err) {
    console.warn(
      '[text-editor]: failed to parse intial state. Set as text',
      err,
    );
  }
  const root = $getRoot();
  const p = $createParagraphNode();
  const textNode = $createTextNode(json);
  p.append(textNode);
  root.append(p);
};

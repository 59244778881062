import { CharacterLimitPlugin as LexicalCharacterLimitPlugin } from '@lexical/react/LexicalCharacterLimitPlugin';
import { Button } from '@synoptic/ui-kit/button/button.js';
import { showMoreButton } from './plugin.css';

export const CharacterLimitPlugin = ({
  hiddenCharCount,
  charLimit,
  withShowMore,
  setHiddenCharCount,
}: {
  hiddenCharCount: number | null;
  charLimit: number;
  withShowMore?: boolean;
  setHiddenCharCount: (count: number) => void;
}) => {
  return (
    <LexicalCharacterLimitPlugin
      charset="UTF-8"
      maxLength={hiddenCharCount ? charLimit - hiddenCharCount : charLimit}
      renderer={({ remainingCharacters }) => {
        if ((!hiddenCharCount && remainingCharacters >= 0) || !withShowMore) {
          return <></>;
        }
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setHiddenCharCount(remainingCharacters);
            }}
            variant="ghost"
            size={'small'}
            className={showMoreButton}
          >
            {hiddenCharCount ? 'Show less' : 'Show more'}
          </Button>
        );
      }}
    />
  );
};

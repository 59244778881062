import { MentionDecorate } from '@synoptic/lexical-nodes/mention.js';
import { EditableMentionNodeComponent } from './editable-mention-node-component';
import { MentionNodeComponent } from './mention-node-component';

export const decorateMentionNode: MentionDecorate = (
  editor,
  _config,
  { key, text, username },
) => {
  return editor.isEditable() ? (
    <EditableMentionNodeComponent nodeKey={key} text={text} />
  ) : (
    <MentionNodeComponent username={username} />
  );
};

/**
 * based on
 * https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/nodes/MentionNode.ts
 * and
 * https://github.com/sodenn/lexical-beautiful-mentions/blob/main/plugin/src/MentionNode.tsx
 */

import {
  $applyNodeReplacement,
  DecoratorNode,
  EditorConfig,
  LexicalEditor,
  SerializedLexicalNode,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type LexicalNode,
  type NodeKey,
  type Spread,
} from 'lexical';

export type SerializedMentionNode = Spread<
  {
    userId: string;
    username: string;
  },
  SerializedLexicalNode
>;

export type MentionDecorate = (
  editor: LexicalEditor,
  config: EditorConfig,
  ctx: {
    userId: string;
    username: string;
    key: NodeKey;
    text: string;
  },
) => unknown;

let decorate: MentionDecorate = () => null;
let domain: string | undefined;

// TODO: remove global state. Replace with Node Overrides https://lexical.dev/docs/concepts/node-replacement
export const globalConfigureMentionNode = (args: {
  decorate?: MentionDecorate;
  domain?: string;
}) => {
  if (args.decorate) {
    decorate = args.decorate;
  }
  if (args.domain) {
    domain = args.domain;
  }
};

function $convertMentionElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const userName = domNode.textContent;
  const userId = domNode.getAttribute('data-lexical-mention-user-id');

  if (userName !== null && userId !== null) {
    const node = $createMentionNode(userName, userId);
    return {
      node,
    };
  }

  return null;
}

export class MentionNode extends DecoratorNode<unknown> {
  __userId: string;
  __username: string;
  __domain?: string;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__username, node.__userId, node.__key);
  }
  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    return $createMentionNode(serializedNode.username, serializedNode.userId);
  }

  constructor(username: string, userId: string, key?: NodeKey) {
    super(key);
    this.__userId = userId;
    this.__username = username;
  }

  exportJSON(): SerializedMentionNode {
    return {
      userId: this.__userId,
      username: this.__username,
      type: 'mention',
      version: 1,
    };
  }

  createDOM(): HTMLElement {
    if (domain) {
      return document.createElement('a');
    } else {
      return document.createElement('span');
    }
  }

  updateDOM(): false {
    return false;
  }

  exportDOM(): DOMExportOutput {
    let element: HTMLElement;

    if (domain) {
      element = document.createElement('a');
      element.setAttribute(
        'href',
        `https://${this.__domain}/${this.__username}`,
      );
    } else {
      element = document.createElement('span');
    }

    element.setAttribute('data-lexical-mention', 'true');
    element.textContent = this.getTextContent();
    element.setAttribute('data-lexical-mention-user-id', this.__userId);

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute('data-lexical-mention')) {
          return null;
        }
        return {
          conversion: $convertMentionElement,
          priority: 1,
        };
      },
    };
  }

  getTextContent(): string {
    return '@' + this.__username;
  }
  isIsolated(): boolean {
    return true;
  }

  decorate(editor: LexicalEditor, config: EditorConfig): unknown {
    return decorate(editor, config, {
      key: this.getKey(),
      text: this.getTextContent(),
      userId: this.__userId,
      username: this.__username,
    });
  }
}

export function $createMentionNode(
  userName: string,
  userId: string,
): MentionNode {
  const mentionNode = new MentionNode(userName, userId);
  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(
  node: LexicalNode | null | undefined,
): node is MentionNode {
  return node instanceof MentionNode;
}

import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { LinkNode } from '@synoptic/lexical-nodes/link.ts';
import { $getNodeByKey } from 'lexical';
import { useNavigate } from 'react-router';

const isSameOrigin = (url: URL) => {
  const origin = url.origin.replace('www.', '');

  const currentOrigin = window.location.origin.replace('www.', '');

  return origin === currentOrigin;
};

export const ClickableLinkPlugin = () => {
  const navigate = useNavigate();

  return (
    <NodeEventPlugin
      nodeType={LinkNode}
      eventType={'click'}
      eventListener={(e, _editor, nodeKey) => {
        const event = e as MouseEvent;
        event.stopPropagation();
        event.preventDefault();

        const node = $getNodeByKey(nodeKey) as LinkNode;

        const url = new URL(node.getURL());

        if (isSameOrigin(url)) {
          return navigate(url.pathname + url.search);
        }

        window.open(url, '_blank');
      }}
    />
  );
};

import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import {
  InitialConfigType,
  InitialEditorStateType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { makePostLexicalNodes } from '@synoptic/lexical-nodes/make-post-lexical-nodes.ts';
import { globalConfigureMentionNode } from '@synoptic/lexical-nodes/mention.ts';
import { useFocusOutside } from '@synoptic/ui-kit/utils/use-focus-outside.ts';
import clsx from 'clsx';
import { LexicalEditor } from 'lexical';
import { useState } from 'react';
import { AutoLinkPlugin } from './plugins/auto-link';
import { CharacterLimitPlugin } from './plugins/character-limit-plugin/plugin';
import { ClickableLinkPlugin } from './plugins/clickable-link';
import { FloatingLinkEditorPlugin } from './plugins/floating-link-editor/plugin';
import { FloatingTextFormatToolbarPlugin } from './plugins/floating-menu/plugin';
import { decorateMentionNode } from './plugins/mentions/decorate';
import { MentionsPlugin } from './plugins/mentions/plugin';
import {
  editorContainer,
  editorContentEditableContainer,
  editorPlaceholder,
} from './text-editor.css';
import { baseTheme } from './themes/base.css';
import { textareaTheme } from './themes/textarea.css';
import { $prepopulateJson } from './utils/prepopulate-json';
import { validateUrl } from './utils/url';

globalConfigureMentionNode({
  decorate: decorateMentionNode,
});

const nodes = makePostLexicalNodes();

type TextEditorProps = React.ComponentPropsWithoutRef<'div'> & {
  namespace: string;
  contentEditableClassName?: string;
  placeholder?: string;
  autoFocus?: boolean;
  onChange?: React.ComponentPropsWithoutRef<typeof OnChangePlugin>['onChange'];
  editable: boolean;
  charLimit?: number;
  withShowMore?: boolean;
  editorRef?: React.Ref<LexicalEditor>;
  theme?: 'base' | 'textarea';
  initialState?: InitialEditorStateType;
  disabled?: boolean;

  /**
   * Callback to be called when focus moves outside of the editor's react tree including portals
   */
  onFocusOutside?: (e: FocusEvent) => void;

  rich?: boolean;
};

const themes = {
  base: baseTheme,
  textarea: textareaTheme,
} as const;

const TextEditorComponent = ({
  placeholder,
  autoFocus,
  onChange,
  editable,
  initialState,
  editorRef,
  theme = 'base',
  className,
  namespace,
  contentEditableClassName,
  charLimit,
  withShowMore,
  rich = false,
  disabled,
  onFocusOutside,

  ...containerDivProps
}: TextEditorProps) => {
  const initialConfig: InitialConfigType = {
    namespace,
    theme: themes[theme],
    nodes,
    onError(err) {
      throw err;
    },
    // TODO: react to editable updates https://lexical.dev/docs/concepts/read-only#setting-the-mode
    editable: editable && !disabled,
    editorState:
      typeof initialState === 'string'
        ? (editor) => $prepopulateJson(editor, initialState)
        : initialState,
  };

  const [hiddenCharCount, setHiddenCharCount] = useState<number | null>(null);

  const [anchorElem, setAnchorElem] = useState<HTMLElement | null>(null);

  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  const { onFocusCapture, onBlurCapture } = useFocusOutside(onFocusOutside);

  const contentEditable = (
    // extra div is needed because of https://github.com/facebook/lexical/pull/6901
    <div className={editorContentEditableContainer}>
      <ContentEditable
        placeholder={
          <div
            className={clsx(contentEditableClassName, editorPlaceholder[theme])}
          >
            {placeholder}
          </div>
        }
        aria-placeholder={placeholder || ''}
        className={contentEditableClassName}
        disabled={disabled}
      />
    </div>
  );

  return (
    <LexicalComposer key={hiddenCharCount} initialConfig={initialConfig}>
      <div
        onFocusCapture={onFocusCapture}
        onBlurCapture={onBlurCapture}
        ref={setAnchorElem}
        className={clsx(editorContainer, className)}
        {...containerDivProps}
      >
        {rich ? (
          <>
            <RichTextPlugin
              contentEditable={contentEditable}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <ListPlugin />
            {anchorElem ? (
              <>
                <FloatingTextFormatToolbarPlugin
                  setIsLinkEditMode={setIsLinkEditMode}
                  anchorElem={anchorElem}
                />
                <FloatingLinkEditorPlugin
                  setIsLinkEditMode={setIsLinkEditMode}
                  anchorElem={anchorElem}
                  isLinkEditMode={isLinkEditMode}
                />
              </>
            ) : null}
          </>
        ) : (
          <PlainTextPlugin
            contentEditable={contentEditable}
            ErrorBoundary={LexicalErrorBoundary}
          />
        )}
        <HistoryPlugin />
        {autoFocus ? <AutoFocusPlugin /> : null}
        <LinkPlugin validateUrl={rich ? validateUrl : undefined} />
        <AutoLinkPlugin />
        {charLimit ? (
          <CharacterLimitPlugin
            hiddenCharCount={hiddenCharCount}
            charLimit={charLimit}
            withShowMore={withShowMore}
            setHiddenCharCount={setHiddenCharCount}
          />
        ) : null}
        {editable ? null : <ClickableLinkPlugin />}
        {editable ? <MentionsPlugin /> : null}
        {onChange ? (
          <OnChangePlugin onChange={onChange} ignoreSelectionChange />
        ) : null}
        {editorRef ? <EditorRefPlugin editorRef={editorRef} /> : null}
        <ClearEditorPlugin />
      </div>
    </LexicalComposer>
  );
};

export const TextEditor = (props: TextEditorProps) => {
  // TextEditor is uncontrolled and can't be updated when new initialValue is passed
  // this can lead to stale data when navigating from one post page to another or inside virtualized feeds
  // remount editor when rendering in a new place
  return <TextEditorComponent key={props.namespace} {...props} />;
};
